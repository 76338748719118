/* eslint-disable no-extra-boolean-cast */
import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import AppError from "@/AppError";
import CustomControl from "@/components/Form/CustomControl";
import Section from "@/components/Section";
import { BusinePartnerEntity } from "@/constants/forms";
import SectionTitle from "@/components/SectionTitle";
import {
  defaultDealerId,
  CREATE,
  CUSTOMER_VALUE,
  DOWNGRADED_VALUE,
  EDIT,
  LEAD_VALUE,
  LEVEL2_VALUE,
  LEVEL3_VALUE,
  marketCodeKey,
  SPECIAL_CUSTOMER,
  SUB_TYPE_SCHEME_NAME,
  VIEW,
  CLEAR,
  BMW_ACCOUNT,
  FUTURE_PROSPECT,
  GreyMarketCheckResult_SellingNotAllowed 
} from "@/constants/constants";
import {
  CHECKBOX,
  DATE,
  LOOKUP,
  NUMBER,
  SELECT,
  SWITCH,
  TEXT,
  
} from "@/constants/forms";
import { BusinessPartnerDetailsContext } from "@/context/BusinessPartnerDetailsContext";
import apiUIData from "@/services/apiUI";
import { handleApiError } from "@/utils/handleApiError";
import { AppContext } from "@/context/AppContext";
import { filterRequiredFields, getNatioanlId3Label, isFieldNotAllowedToViewValue, isFieldRequired } from "@/helperFunctions/businessPartners";
import { useQuery } from "@tanstack/react-query";
import {
  useBPMainInitialQueries,
  useBPRequiredFields,
  useFetchNewL2BPs,
} from "@/services/queries";
import VatNotApplicableDialog from "@/components/BusinessPartnerDetails/VatNotApplicableDialog";
import { useDebounce } from "@/hooks/useDebounce";
import { useTranslation } from "react-i18next";
import { showCoin,showGreySection } from "@/helperFunctions/generic";
import useFirstRender from "@/hooks/useFirstRender";
import { formatISO } from "date-fns";

const BPMainDetails = ({
  businessPartner,
  owningRetailer,
  control,
  filteredNkamOptions,
  mode,
  permissions,
  resetField,
  watch,
  setValue,
  termofcreditoptions,
  gettermsofcreditoption,
  shouldDisableNKAMStatus,
  register,
  isBpDetailsFetched,
  isGCDMEnabled,
  allowedStatuses
}) => {
  const {
    companyHierarchyOptions,
    downgradeReasonOptions,
    allowedLegalEntityTypeOptions,
    level2BusinessPartners,
    owningRetailerOptions,
    salesRegionOptions,
    subTypeOptions,
    owningKamServiceUsers,
    invoiceLanguageOptions,
    setSubTypeOptions,
    setLevel2BusinessPartners,
    greyResultOptions,
    requiredFields,
    setRequiredFields
  } = useContext(BusinessPartnerDetailsContext);
  const {
    countryOptions,
    userData: { [marketCodeKey]: marketCode, [defaultDealerId]: dealerId },
    coinResultStatuses,
    typeOptions,
  } = useContext(AppContext);
  const isFirstRender = useFirstRender();

  const [isEditable, setIsEditable] = useState(false);
  const [owningRetailerContactOptions, setOwningRetailerContactOptions] =
    useState([]);
  const [showOwningRetailerForBe, setShowOwningRetailerForBe] = useState(true);
  const [filteredReqFields, setFilteredReqFields] = useState(requiredFields);
  const [apiError, setApiError] = useState({});
  const companyHierarchy = watch(BusinePartnerEntity.companyHierarchy);
  const type = watch(BusinePartnerEntity.type);
  const l2ParentCompany = watch(BusinePartnerEntity.l2ParentCompany);
  const hasBillingAddress = watch(BusinePartnerEntity.hasBillingAddress);
  const gcdmId = watch(BusinePartnerEntity.gcdmId);
  const isDowngraded = watch(BusinePartnerEntity.nkamStatus) === DOWNGRADED_VALUE;
  const isFutureProspect = watch(BusinePartnerEntity.downgradeReason) === FUTURE_PROSPECT;
  const [showOwingKaminEdit, setOwingKaminEdit] = useState(false);
  const [isStreetPOBoxCheck, setStreetPOBoxCheck] = useState(false);
  const [vatNotApplicableConfig, setVatNotApplicableConfig] = useState(false);
  const [cloneVatConfig, setCloneVatConfig] = useState(false);
  const isCustomerOrLead =
    watch(BusinePartnerEntity.nkamStatus) === LEAD_VALUE ||
    watch(BusinePartnerEntity.nkamStatus) === CUSTOMER_VALUE;
  const isGreyPerformed = watch(BusinePartnerEntity.greymarketcheckperformed);

  const isOwningRetailer = watch(BusinePartnerEntity.owningRetailer)?.id;
  const defaultInvoiceLanguage = watch(BusinePartnerEntity.invoiceLanguage);
  const bPVatNotApplicable = watch(BusinePartnerEntity.vatNotApplicable);
  const [vatNotApplicableModalString, setVatNotApplicableModalString] =
    useState("");
  const [vatNotApplicableModalMemo, setVatNotApplicableModalMemo] =
    useState("");
    const [manageGreyMarketConfig, setManageGreyMarketConfig] =
    useState("");
  const [l2InputValue, setL2InputValue] = useState(null);
  const [inputReason, setInputReason] = useState(CLEAR);
  const [isTyping, setIsTyping] = useState(false);
  const [restrictL3toL2, setRestrictL3toL2] = useState(false);
  const [countyRequiredFields, setCountyRequiredFields] = useState([]);
  const [restrictedFields, setRestrictedFields] = useState([]);
  const [
    isCountrySelectedId,
    isAddr2CountrySelectedId,
    selectedOwningRetailer,
    nkamStatus,
    address1Line1,
    postbox,
    address2Line1,
    address2PostBox,
    greyPerformed,
    greyMarketResult,
  ] = watch([
    BusinePartnerEntity.country,
    BusinePartnerEntity.address2Country,
    BusinePartnerEntity.owningRetailer,
    BusinePartnerEntity.nkamStatus,
    BusinePartnerEntity.address1Line1,
    BusinePartnerEntity.postbox,
    BusinePartnerEntity.address2Line1,
    BusinePartnerEntity.address2PostBox,
    BusinePartnerEntity.greymarketcheckperformed,
    BusinePartnerEntity.greymarketcheckresult
  ]);
  const [nationalId1Label, setNationalId1Label] = useState(
    BusinePartnerEntity.nationalId1,
  );
  const [nationalId2Label, setNationalId2Label] = useState(
    BusinePartnerEntity.nationalId2,
  );
  const [nationalId3Label, setNationalId3Label] = useState(
    BusinePartnerEntity.nationalId3,
  );
  const [filteredInvoiceLanguages, setFilteredInvoiceLanguages] = useState([]);
  const [countyList, setCountyList] = useState([]);
  const [address2CountyList, setAddress2CountyList] = useState([]);
  const [disableNationalID1, setDisableNationalID1] = useState(
    businessPartner?.vatCloned,
  );
  const [showVatNotApplicableDialog, setShowVatNotApplicableDialog] =
    useState(false);
  const [disableStatusField, setDisableStatusField] = useState(false);
  // const [disablegreySection, setDisableGreySection] = useState(false);
  const debouncedL2Value = useDebounce([l2InputValue], 500);
  const { t } = useTranslation();

  // uses equlity check to identify if a field is required or not.
  const isRequiredField = (schemaName) => {
    return isFieldRequired(schemaName, filteredReqFields, countyRequiredFields, marketCode, isCountrySelectedId);
  };

  const l2BpsListQuery = useFetchNewL2BPs(
    companyHierarchy,
    debouncedL2Value,
    mode,
    inputReason
  );
  const initialQueries = useBPMainInitialQueries(isBpDetailsFetched);

  const owningRetailerContactOptionsQuery = useQuery({
    queryKey: ["owningRetailerContactOptions", [isOwningRetailer]],
    queryFn: async ({ signal }) => {
      return apiUIData.getOwningRetailerContactOptions(
        isOwningRetailer,
        signal,
      );
    },
    enabled: Boolean(isOwningRetailer) && isBpDetailsFetched,
  });

  const getCountyListQuery = useQuery({
    queryKey: ["countyList", [isCountrySelectedId]],
    queryFn: async ({ signal }) => {
      resetField("county");
      return apiUIData.getCountyList(isCountrySelectedId, signal);
    },
    enabled: Boolean(isCountrySelectedId),
  });

  const getBillingCountyListQuery = useQuery({
    queryKey: ["countyList", [isAddr2CountrySelectedId]],
    queryFn: async ({ signal }) => {
      resetField("address2County");
      return apiUIData.getCountyList(isAddr2CountrySelectedId, signal);
    },
    enabled: Boolean(isAddr2CountrySelectedId),
  });

  const getSubTypeOptionsQuery = useQuery({
    queryKey: ["subTypeOptions", [type]],
    queryFn: async ({ signal }) => {
      resetField("subType");
      return apiUIData.getSubTypeOptions(type?.id || null, signal);
    },
    enabled: isBpDetailsFetched && Boolean(mode !== VIEW),
  });

  const isInitialQueriesLoading = initialQueries.some((q) => q.isLoading);

  useEffect(() => {
    setValue("termOfCredit", businessPartner?.termOfCredit?.id);
    if (countryOptions.length) {
      let userMarketValue = countryOptions.find(
        (c) => c.isoCode === marketCode,
      )?.value;
      !businessPartner?.address2Country?.value && setValue(BusinePartnerEntity.address2Country, userMarketValue);      
      !businessPartner?.country?.value && setValue(BusinePartnerEntity.country, userMarketValue);
    }
  }, []);

  useEffect(() => {
    if (isInitialQueriesLoading) {
      return;
    }
    const isError = initialQueries.some((q) => q.isError);
    if (isError) {
      let error = initialQueries.find((q) => q.isError)?.error;
      setApiError(handleApiError(error));
    }
    if (initialQueries.every((q) => q.isSuccess)) {
      const { description: NationalId1Label, booleanValue: Nat1Boolean } =
        initialQueries[0]?.data?.data?.result || {};
      const { description: NationalId2Label, booleanValue: Nat2Boolean } =
        initialQueries[1]?.data?.data?.result || {};
      const { booleanValue: allowOwningKamEdit } =
        initialQueries[3]?.data?.data?.result || {};
      const { booleanValue: streetPOBoxCheck } =
        initialQueries[4]?.data?.data?.result || {};
      const { booleanValue: vatNotApplicableConfigResult } =
        initialQueries[6]?.data?.data?.result || {};
      let vatNotApplicableConfigMessageMemo =
        initialQueries[7]?.data?.data?.result?.description || {};
      let vatNotApplicableConfigMessageString =
        initialQueries[7]?.data?.data?.result?.stringValue || {};
      const { booleanValue: cloneVatConfig } =
        initialQueries[8]?.data?.data?.result || {};
      let manageGreyMarketConfigString =
        initialQueries[9]?.data?.data?.result?.stringValue || {};
      let {stringValue: countyRequiredFieldsString, booleanValue: countyRequiredFieldsBool} = initialQueries[11]?.data?.data?.result || {};
      setNationalId1Label(
        (Nat1Boolean && NationalId1Label) ||
        t("businessPartnerDetailPage.mainDetails.nationalId1"),
      );
      setNationalId2Label(
        (Nat2Boolean && NationalId2Label) ||
        t("businessPartnerDetailPage.mainDetails.nationalId2"),
      );
      if (allowOwningKamEdit) {
        if (mode === EDIT) {
          setOwingKaminEdit(true);
        }
      } else {
        setOwingKaminEdit(false);
      }
      if (streetPOBoxCheck) {
        if (mode != VIEW) {
          setStreetPOBoxCheck(true);
        }
      } else {
        setStreetPOBoxCheck(false);
      }
      if (
        vatNotApplicableConfigResult &&
        vatNotApplicableConfigResult === true
      ) {
        setVatNotApplicableConfig(true);
      }
      if (vatNotApplicableConfigMessageString) {
        setVatNotApplicableModalString(vatNotApplicableConfigMessageString);
      }
      if (vatNotApplicableConfigMessageMemo) {
        setVatNotApplicableModalMemo(vatNotApplicableConfigMessageMemo);
      }
      if (cloneVatConfig && cloneVatConfig == true) {
        setCloneVatConfig(true);
      }
      if (manageGreyMarketConfigString) {
        setManageGreyMarketConfig(JSON.parse(manageGreyMarketConfigString??""));
      }
      setRestrictL3toL2(initialQueries[10].data?.data?.result?.booleanValue);
      countyRequiredFieldsBool && setCountyRequiredFields(JSON.parse(countyRequiredFieldsString));;
      setRestrictedFields([...fetchAccountRestrictedFields(initialQueries[12].data?.data?.result)]);
    }
  }, [isInitialQueriesLoading]);

  useEffect(() => {
      const { booleanValue, description } = initialQueries[2].data?.data?.result || {};
      const defaultLabel = t("businessPartnerDetailPage.mainDetails.nationalId3");
      const parsedLabel = getNatioanlId3Label(
        description,
        booleanValue,
        defaultLabel,
        isCountrySelectedId,
      );
      setNationalId3Label(booleanValue ? parsedLabel : defaultLabel);
    }, [initialQueries[2]?.dataUpdatedAt, isCountrySelectedId]);

  useEffect(() => {
    if (initialQueries[5].isSuccess && invoiceLanguageOptions.length) {
      const { stringValue, description } =
        initialQueries[5].data?.data?.result || {};
      const statusValues = description?.split("|") || [];
      const filteredLanguages = invoiceLanguageOptions.filter((option) =>
        statusValues.includes(option.value.toString()),
      );
      setFilteredInvoiceLanguages(filteredLanguages);
      if (!defaultInvoiceLanguage) {
        setValue(BusinePartnerEntity?.invoiceLanguage, stringValue);
      }
    }
  }, [invoiceLanguageOptions, initialQueries[5]?.dataUpdatedAt]);


  useEffect(() => {
    if (owningRetailerContactOptionsQuery.isLoading) {
      return;
    }
    if (owningRetailerContactOptionsQuery.isError) {
      let error = owningRetailerContactOptionsQuery.error;
      setApiError(handleApiError(error));
    }
    if (owningRetailerContactOptionsQuery.isSuccess) {
      setOwningRetailerContactOptions(
        owningRetailerContactOptionsQuery.data.data.result,
      );
    }
  }, [
    owningRetailerContactOptionsQuery.dataUpdatedAt,
    owningRetailerContactOptionsQuery.errorUpdatedAt,
  ]);

  useEffect(() => {
    if (getCountyListQuery.isError) {
      let error = getCountyListQuery.error;
      setApiError(handleApiError(error));
    }
    if (getCountyListQuery.isSuccess) {
      setCountyList(getCountyListQuery.data.data.result);
    }
  }, [getCountyListQuery.dataUpdatedAt, getCountyListQuery.errorUpdatedAt]);

  useEffect(() => {
    if (getBillingCountyListQuery.isError) {
      let error = getBillingCountyListQuery.error;
      setApiError(handleApiError(error));
    }
    if (getBillingCountyListQuery.isSuccess) {
      setAddress2CountyList(getBillingCountyListQuery.data.data.result);
    }
  }, [
    getBillingCountyListQuery.dataUpdatedAt,
    getBillingCountyListQuery.errorUpdatedAt,
  ]);

  useEffect(() => {
    if (getSubTypeOptionsQuery?.isError) {
      let error = getSubTypeOptionsQuery?.error;
      setApiError(handleApiError(error));
    }
    if (getSubTypeOptionsQuery?.isSuccess) {
      setSubTypeOptions(getSubTypeOptionsQuery?.data?.data?.result);
    }
  }, [
    getSubTypeOptionsQuery?.dataUpdatedAt,
    getSubTypeOptionsQuery?.errorUpdatedAt,
    getSubTypeOptionsQuery?.status,
  ]);

  useEffect(() => {
    if (l2BpsListQuery?.isFetching) return;
    if (l2BpsListQuery?.isError) {
      setApiError(handleApiError(l2BpsListQuery?.error));
    }
    if (l2BpsListQuery?.isSuccess) {
      setLevel2BusinessPartners(l2BpsListQuery?.data?.data?.result || []);
    }
    setIsTyping(false);
  }, [l2BpsListQuery?.dataUpdatedAt, l2BpsListQuery?.errorUpdatedAt]);

  useEffect(() => {
    if (permissions) {
      permissionToEditOwningRetailer();
    }

    if (marketCode === "BE" && !permissions?.agencyModelRestricted) {
      setShowOwningRetailerForBe(false);
    }
  }, [companyHierarchy, permissions]);

  useEffect(() => {
    gettermsofcreditoption(type?.id, companyHierarchy, l2ParentCompany);
  }, [l2ParentCompany, type?.id]);

  useEffect(() => {
    AddtoRequriedFields(type);
    resetFieldTermofCredit();
    setValue(BusinePartnerEntity.subType, null);
  }, [type?.id]);

  useEffect(() => {
    if (isStreetPOBoxCheck) {
      setFilteredReqFields(
        filterRequiredFields(
          requiredFields,
          address1Line1,
          postbox,
          address2Line1,
          address2PostBox,
          hasBillingAddress,
        ),
      );
    } else {
      setFilteredReqFields(requiredFields);
    }
  }, [
    address1Line1,
    postbox,
    address2Line1,
    address2PostBox,
    requiredFields,
  ]);


  useEffect(() => {
    const statusDisable = statusDisableValidation();
    setDisableStatusField(statusDisable)
    // Created for nkam-24358
    // clubbing here instead of new ueseffecthook
    // TODO: check why setValue is not used as its a standard way of setting value to dynamic form fields.
    if(companyHierarchy === LEVEL2_VALUE){
      setValue(BusinePartnerEntity.l2ParentCompany,"",{ shouldDirty: true });
          setL2InputValue(null);
    }
  }, [
    shouldDisableNKAMStatus,
    gcdmId,
    companyHierarchy,
    businessPartner?.coinCheckResult?.value,
    businessPartner?.greyMarketCheckResult?.value,
  ]);

  // TODO: make it generic to fetch restrictedfields for any entity.
  const fetchAccountRestrictedFields = (config) => {
    // handle null scenarios.
    if (config === undefined || config === null
      || !config?.booleanValue|| config?.stringValue === undefined 
      || config?.stringValue === null || config?.stringValue === "") return [];

    const jsonData = JSON.parse(config.stringValue || "");
    const restrictedEntities = jsonData?.restrictedEntities || [];
    const account = restrictedEntities.find((entity)=> entity?.schemaName == "account")
    const marketFilteredRestrictedFields = account?.restrictedFields?.filter((restrictedField)=>restrictedField?.markets?.includes(marketCode))
    const restrictedFields = []
    for (const restrictedField of marketFilteredRestrictedFields) {
      if(restrictedField?.schemaName !== undefined || restrictedField?.schemaName !== null || restrictedField?.schemaName !== ""){
        restrictedFields.push(restrictedField.schemaName);
      }
    }

    return restrictedFields;
  }

  const statusDisableValidation = () => {
    if (
      businessPartner?.coinCheckResult?.value === 174640001 ||
      businessPartner?.greyMarketCheckResult?.value === 174640001 ||
      companyHierarchy === LEVEL3_VALUE ||
      shouldDisableNKAMStatus ||
      (!Boolean(gcdmId) && isGCDMEnabled)
    ) {
      return true;
    } else {
      return false;
    }
  }
  const AddtoRequriedFields = (type) => {
    if (type?.name === SPECIAL_CUSTOMER) {
      setFilteredReqFields([...requiredFields, SUB_TYPE_SCHEME_NAME]);
    } else {
      if (requiredFields?.length) {
        let filteredRequiredFields = requiredFields?.filter(
          function (schemaName) {
            return schemaName !== SUB_TYPE_SCHEME_NAME;
          },
        );
        setFilteredReqFields(filteredRequiredFields);
      }
    }
  };

  const permissionToEditOwningRetailer = () => {
    const { retailerGroupIsOwningRetailer } = permissions;

    if (retailerGroupIsOwningRetailer && companyHierarchy === LEVEL2_VALUE) {
      setIsEditable(true);
    } else {
      resetField(BusinePartnerEntity.owningRetailer, {
        defaultValue: businessPartner?.owningRetailer,
      });
      resetField(BusinePartnerEntity.owningRetailerContact, {
        defaultValue: businessPartner?.owningRetailerContact,
      });
      setIsEditable(false);
    }
  };

  useEffect(() => {
    if (!isDowngraded) {
      resetDowngradeFields();
    }
  }, [isDowngraded]);

  useEffect(() => {
    if (l2InputValue) {
      setLevel2BusinessPartners([]);
      setIsTyping(true);
    }
  }, [l2InputValue]);

  const resetFieldTermofCredit = () => {
    resetField(BusinePartnerEntity.termOfCredit);
  };
  // Modified for NKAM-23845
  // Removed resetFieldsRelatedToLeadStatus functions functionality as it's not a valid functionality.
  // It was developed as part of NKAM-12254 & NKAM-13534.
  // But as per the mentioned tickets the functionality is to make the field mandatory and not to reset the fields. Hence removing this functionality.
  // To make the fields mandatory we have now configs based on status. So no need to make the field mandatory manually.

  const resetDowngradeFields = () => {
    resetField(BusinePartnerEntity.downgradeDate);
    resetField(BusinePartnerEntity.futureProspectDate);
    resetField(BusinePartnerEntity.downgradeDescription);
    resetField(BusinePartnerEntity.downgradeReason);
  };

  useEffect(() => {
    if (!hasBillingAddress) {
      resetBillingAddressFields();
    }
  }, [hasBillingAddress]);

  const resetBillingAddressFields = () => {
    resetField(BusinePartnerEntity.address2BuildingNumber);
    resetField(BusinePartnerEntity.address2Line1);
    resetField(BusinePartnerEntity.address2Line2);
    resetField(BusinePartnerEntity.address2Line3);
    resetField(BusinePartnerEntity.address2PostCode);
    resetField(BusinePartnerEntity.address2PostBox);
    resetField(BusinePartnerEntity.address2City);
    resetField(BusinePartnerEntity.address2County);
    resetField(BusinePartnerEntity.address2SalesRegion);
  };

  const handleCountryChange = () => {
    setDisableNationalID1(false);
    setValue(BusinePartnerEntity.county, "", { shouldDirty: true })
  };

  const handleCountry2Change = () => {
    setValue(BusinePartnerEntity.address2County,"", { shouldDirty: true })
  };

  useEffect(() => {
    if (!selectedOwningRetailer) {
      let defaultDealer = owningRetailerOptions.find(
        (retailer) => retailer.id === dealerId,
      );
      if (!defaultDealer && owningRetailerOptions.length) {
        setValue(BusinePartnerEntity.owningRetailer, owningRetailerOptions[0]);
      } else {
        setValue(BusinePartnerEntity.owningRetailer, defaultDealer);
      }
    }
  }, [owningRetailerOptions]);

  useEffect(() => {
    if(!isFirstRender) {
      if(greyPerformed) {
        setValue(BusinePartnerEntity.greymarketcheckdate, formatISO(new Date()).toString(), { shouldDirty: true });
      } else {
        setValue(BusinePartnerEntity.greymarketcheckdate, "", { shouldDirty: true });
        setValue(BusinePartnerEntity.greymarketcheckresult, null, { shouldDirty: true });
      }
    }
  },[greyPerformed]);

  useEffect(() => {
    if(!isFirstRender && mode === EDIT) {
      const isGreySectionEnabled = getShowGreySectionBool();
      if (greyMarketResult?.value === GreyMarketCheckResult_SellingNotAllowed && isGreySectionEnabled && greyPerformed && allowedStatuses.includes(DOWNGRADED_VALUE.toString())) {    
        setValue(BusinePartnerEntity.nkamStatus, DOWNGRADED_VALUE, { shouldDirty: true });
        setDisableStatusField(true);
      } else {
        const statusDisable = statusDisableValidation();
        setDisableStatusField(statusDisable)
      }
    }
  }, [greyMarketResult, type])

  useEffect(() => {
    return () => {
      // queryClient.invalidateQueries();
    };
  }, []);

  const vatNotApplicableOnChange = () => {
    setShowVatNotApplicableDialog(true);
  };

  const vatNotApplicablehandleOk = () => {
    if (bPVatNotApplicable) {
      // VatNotApplicable false to true "Checked"
      setValue(BusinePartnerEntity?.vatNumber, "");
    }
    setShowVatNotApplicableDialog(false);
  };

  const vatNotApplicablehandleClose = () => {
    setValue(BusinePartnerEntity?.vatNotApplicable, !bPVatNotApplicable);
    setShowVatNotApplicableDialog(false);
  };

  const getShowCoinBool = () => {
    return showCoin(BMW_ACCOUNT, type, typeOptions);
  }
  const getShowGreySectionBool = () => {

    return showGreySection( typeOptions,type,manageGreyMarketConfig,marketCode);
  }

  /**
   *   @variation NKAM-13218 / Sai Padamata / 09-01-2023 / Business Partner - CRM+Portal - Form Cleansing
   */
  // TODO we have make name parameter in sync with CRM field internalName
  // and moved these orphan strings to a file, its getting un managable now.
  // will fix in recurrent PR
  return (
    <>
      <Section id="details">
        <SectionTitle
          category={true}
          title={t("businessPartnerDetailPage.mainDetails.sectionTitle")}
        />
      </Section>

      <Section>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              label={t("businessPartnerDetailPage.mainDetails.name")}
              mode={mode}
              name={BusinePartnerEntity.name}
              required={true}
              type={TEXT}
              view={businessPartner?.name}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              label={t("businessPartnerDetailPage.mainDetails.legalName")}
              maxLength={160}
              mode={mode}
              name={BusinePartnerEntity.legalName}
              required={true}
              type={TEXT}
              view={businessPartner?.legalName}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              label={t("businessPartnerDetailPage.mainDetails.vatNumber")}
              mode={mode}
              name={BusinePartnerEntity.vatNumber}
              required={
                bPVatNotApplicable
                  ? false
                  : isRequiredField(BusinePartnerEntity.vatNumber)
              }
              type={TEXT}
              view={businessPartner?.vatNumber}
              disabled={bPVatNotApplicable}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              label={t("businessPartnerDetailPage.mainDetails.representedBy")}
              mode={mode}
              name={BusinePartnerEntity.representedBy}
              type={TEXT}
              view={businessPartner?.representedBy}
              required={
                bPVatNotApplicable
                  ? false
                  : isRequiredField("bmw_representedby")
              }
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              label={t(
                "businessPartnerDetailPage.mainDetails.companyHeirarchy",
              )}
              mode={mode}
              options={companyHierarchyOptions}
              name={BusinePartnerEntity.companyHierarchy}
              required={true}
              type={SELECT}
              view={businessPartner?.companyHierarchy?.name}
              disabled = {businessPartner?.companyHierarchy?.value===LEVEL3_VALUE && restrictL3toL2}
            />
          </Grid>

          {vatNotApplicableConfig && (
            <Grid item xs={12} sm={4}>
              <CustomControl
                control={control}
                label={t(
                  "businessPartnerDetailPage.mainDetails.vatNotApplicable",
                )}
                mode={mode}
                name={BusinePartnerEntity.vatNotApplicable}
                type={CHECKBOX}
                view={businessPartner?.vatNotApplicable}
                onCheckboxChange={vatNotApplicableOnChange}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              label={t("businessPartnerDetailPage.mainDetails.invoiceLanguage")}
              mode={mode}
              options={filteredInvoiceLanguages}
              name={BusinePartnerEntity.invoiceLanguage}
              type={SELECT}
              view={businessPartner?.invoiceLanguage?.name}
              required={isRequiredField("bmw_invoicelanguage")}
              showPlaceholderOption={!isRequiredField("bmw_invoicelanguage")}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              label={t("businessPartnerDetailPage.mainDetails.url")}
              mode={mode}
              name={BusinePartnerEntity.url}
              type={TEXT}
              url={businessPartner?.url}
              view={businessPartner?.url}
              required={isRequiredField("websiteurl")}
            />
          </Grid>

          {companyHierarchy === LEVEL3_VALUE && (
            <Grid item xs={12} sm={4}>
              <CustomControl
                control={control}
                label={t("businessPartnerDetailPage.mainDetails.l2Parent")}
                mode={mode}
                name={BusinePartnerEntity.l2ParentCompany}
                options={level2BusinessPartners}
                required={true}
                type={LOOKUP}
                view={businessPartner?.l2ParentCompany?.name}
                url={`/businesspartner/${businessPartner?.l2ParentCompany?.id}`}
                setInputValue={setL2InputValue}
                inputValue={l2InputValue}
                loading={l2BpsListQuery?.isFetching || isTyping}
                noOptionsText="No options. Refine your search."
                shouldFilterManually={false}
                setInputReason={setInputReason}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              label={t("businessPartnerDetailPage.mainDetails.businessPhone")}
              mode={mode}
              name={BusinePartnerEntity.mainPhone}
              type={TEXT}
              maxLength={50}
              view={businessPartner?.mainPhone}
              required={isRequiredField("telephone1")}
            />
          </Grid>
        </Grid>
      </Section>

      <Section>
        <SectionTitle title="Main Address" />

        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              label={t("businessPartnerDetailPage.mainDetails.houseNumber")}
              mode={mode}
              name={BusinePartnerEntity.buildingNumber}
              type={TEXT}
              required={isRequiredField("bmw_address1buildingnumber")}
              view={businessPartner?.buildingNumber}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              label={t("businessPartnerDetailPage.mainDetails.street")}
              mode={mode}
              name={BusinePartnerEntity.address1Line1}
              required={isRequiredField("address1_line1")}
              type={TEXT}
              view={businessPartner?.address1Line1}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              label={t("businessPartnerDetailPage.mainDetails.address1")}
              mode={mode}
              name={BusinePartnerEntity.address1Line2}
              type={TEXT}
              view={businessPartner?.address1Line2}
              required={isRequiredField("address1_line2")}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              label={t("businessPartnerDetailPage.mainDetails.address2")}
              mode={mode}
              name={BusinePartnerEntity.address1Line3}
              type={TEXT}
              view={businessPartner?.address1Line3}
              required={isRequiredField("address1_line3")}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              label={t("businessPartnerDetailPage.mainDetails.postcode")}
              mode={mode}
              name={BusinePartnerEntity.address1PostCode}
              required={isRequiredField("address1_postalcode")}
              type={TEXT}
              maxLength={10}
              view={businessPartner?.address1PostCode}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              label={t("businessPartnerDetailPage.mainDetails.postbox")}
              mode={mode}
              name={BusinePartnerEntity.postbox}
              type={TEXT}
              maxLength={20}
              view={businessPartner?.postbox}
              required={isRequiredField("address1_postofficebox")}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              label={t("businessPartnerDetailPage.mainDetails.city")}
              mode={mode}
              name={BusinePartnerEntity.address1City}
              required={isRequiredField("address1_city")}
              type={TEXT}
              view={businessPartner?.address1City}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              label={t("businessPartnerDetailPage.mainDetails.country")}
              mode={mode}
              name={BusinePartnerEntity.country}
              options={countryOptions}
              required={isRequiredField("bmw_country")}
              type={SELECT}
              view={businessPartner?.country?.name}
              onChange={handleCountryChange}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              label={t("businessPartnerDetailPage.mainDetails.county")}
              mode={mode}
              options={countyList}
              name={BusinePartnerEntity.county}
              type={LOOKUP}
              view={businessPartner?.county?.name}
              required={isRequiredField("bmw_address1_countyid")}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              label={t("businessPartnerDetailPage.mainDetails.salesRegion")}
              mode={mode}
              name={BusinePartnerEntity.salesRegion}
              options={salesRegionOptions}
              type={LOOKUP}
              view={businessPartner?.salesRegion?.name}
              required={isRequiredField("bmw_salesregionid")}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              label={t(
                "businessPartnerDetailPage.mainDetails.differentBilling",
              )}
              mode={mode}
              name={BusinePartnerEntity.hasBillingAddress}
              type={SWITCH}
            />
          </Grid>
        </Grid>
      </Section>

      {hasBillingAddress && (
        <Section>
          <SectionTitle
            title={t("businessPartnerDetailPage.mainDetails.billingAddress")}
          />

          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <CustomControl
                control={control}
                label={t(
                  "businessPartnerDetailPage.mainDetails.billingHouseNo",
                )}
                mode={mode}
                name={BusinePartnerEntity.address2BuildingNumber}
                type={TEXT}
                required={isRequiredField("bmw_address2buildingnumber")}
                view={businessPartner?.address2BuildingNumber}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <CustomControl
                control={control}
                label={t("businessPartnerDetailPage.mainDetails.billingStreet")}
                mode={mode}
                name={BusinePartnerEntity.address2Line1}
                required={isRequiredField("address2_line1")}
                type={TEXT}
                view={businessPartner?.address2Line1}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <CustomControl
                control={control}
                label={t(
                  "businessPartnerDetailPage.mainDetails.billingAddress1",
                )}
                mode={mode}
                name={BusinePartnerEntity.address2Line2}
                type={TEXT}
                required={isRequiredField("address2_line2")}
                view={businessPartner?.address2Line2}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <CustomControl
                control={control}
                label={t(
                  "businessPartnerDetailPage.mainDetails.billingAddress2",
                )}
                mode={mode}
                name={BusinePartnerEntity.address2Line3}
                required={isRequiredField("address2_line3")}
                type={TEXT}
                view={businessPartner?.address2Line3}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <CustomControl
                control={control}
                label={t("businessPartnerDetailPage.mainDetails.postcode")}
                mode={mode}
                name={BusinePartnerEntity.address2PostCode}
                required={isRequiredField("address2_postalcode")}
                type={TEXT}
                maxLength={10}
                view={businessPartner?.address2PostCode}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <CustomControl
                control={control}
                label={t("businessPartnerDetailPage.mainDetails.postbox")}
                mode={mode}
                name={BusinePartnerEntity.address2PostBox}
                maxLength={20}
                type={TEXT}
                view={businessPartner?.address2PostBox}
                required={isRequiredField("address2_postofficebox")}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <CustomControl
                control={control}
                label={t("businessPartnerDetailPage.mainDetails.city")}
                mode={mode}
                name={BusinePartnerEntity.address2City}
                type={TEXT}
                view={businessPartner?.address2City}
                required={isRequiredField("address2_city")}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <CustomControl
                control={control}
                label={t("businessPartnerDetailPage.mainDetails.country")}
                mode={mode}
                name={BusinePartnerEntity.address2Country}
                options={countryOptions}
                required={isRequiredField("bmw_address2country")}
                type={SELECT}
                view={businessPartner?.address2Country?.name}
                onChange={handleCountry2Change}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <CustomControl
                control={control}
                label={t("businessPartnerDetailPage.mainDetails.county")}
                mode={mode}
                name={BusinePartnerEntity.address2County}
                type={LOOKUP}
                options={address2CountyList}
                view={businessPartner?.address2County?.name}
                required={isRequiredField("bmw_address2_countyid")}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <CustomControl
                control={control}
                label={t("businessPartnerDetailPage.mainDetails.salesRegion")}
                mode={mode}
                name={BusinePartnerEntity.address2SalesRegion}
                options={salesRegionOptions}
                required={isRequiredField("bmw_address2salesregionid")}
                type={LOOKUP}
                view={businessPartner?.address2SalesRegion?.name}
              />
            </Grid>
          </Grid>
        </Section>
      )}

      <Section>
        <SectionTitle title={t("businessPartnerDetailPage.mainDetails.type")} />

        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              disabled={companyHierarchy === LEVEL3_VALUE}
              label={t("businessPartnerDetailPage.mainDetails.type")}
              mode={mode}
              name={BusinePartnerEntity.type}
              options={typeOptions}
              required={
                companyHierarchy === LEVEL3_VALUE
                  ? false
                  : isCustomerOrLead || isRequiredField("bmw_accounttypeid")
              }
              type={LOOKUP}
              view={businessPartner?.type?.name}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              label={t("businessPartnerDetailPage.mainDetails.subtype")}
              disabled={companyHierarchy === LEVEL3_VALUE}
              mode={mode}
              name={BusinePartnerEntity.subType}
              options={subTypeOptions}
              type={LOOKUP}
              required={isRequiredField("bmw_AccountSubTypeId")}
              view={businessPartner?.subType?.name}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              label={t("businessPartnerDetailPage.mainDetails.legalEntityType")}
              mode={mode}
              name={BusinePartnerEntity.legalEntityType}
              options={allowedLegalEntityTypeOptions}
              type={LOOKUP}
              required={isRequiredField("bmw_legalentitytypeid")}
              view={businessPartner?.legalEntityType?.name}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              disabled={companyHierarchy === LEVEL3_VALUE}
              label={t("businessPartnerDetailPage.mainDetails.termOfCredit")}
              type={SELECT}
              mode={mode}
              options={termofcreditoptions}
              name={BusinePartnerEntity.termOfCredit}
              showPlaceholderOption={true}
              view={businessPartner?.termOfCredit?.name}
            />
          </Grid>
        </Grid>
      </Section>

      {mode !== CREATE && (
        <Section>
          <SectionTitle title="Status" />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <CustomControl
                control={control}
                label={t("businessPartnerDetailPage.mainDetails.nkamStatus")}
                disabled={disableStatusField}
                mode={mode}
                name={BusinePartnerEntity.nkamStatus}
                options={filteredNkamOptions}
                required={bPVatNotApplicable ? false : true}
                type={SELECT}
                view={businessPartner?.nkamStatus?.name}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <CustomControl
                control={control}
                disabled={!isDowngraded}
                label={t(
                  "businessPartnerDetailPage.mainDetails.downgradeReason",
                )}
                mode={mode}
                name={BusinePartnerEntity.downgradeReason}
                options={downgradeReasonOptions}
                required={isDowngraded}
                type={SELECT}
                view={businessPartner?.downgradeReason?.name}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <CustomControl
                control={control}
                disabled={!isDowngraded}
                label={t("businessPartnerDetailPage.mainDetails.downgradeDate")}
                mode={mode}
                name={BusinePartnerEntity.downgradeDate}
                required={isDowngraded}
                type={DATE}
                view={businessPartner?.downgradeDate}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <CustomControl
                control={control}
                disabled={!isDowngraded}
                label={t(
                  "businessPartnerDetailPage.mainDetails.downgradeDescription",
                )}
                mode={mode}
                name={BusinePartnerEntity.downgradeDescription}
                required={isDowngraded}
                type={TEXT}
                view={businessPartner?.downgradeDescription}
              />
            </Grid>
            {isFutureProspect && (
              <Grid item xs={12} sm={4}>
                <CustomControl
                  control={control}
                  disabled={!isFutureProspect}
                  label={t(
                    "businessPartnerDetailPage.mainDetails.futureProspectDate",
                  )}
                  mode={mode}
                  name={BusinePartnerEntity.futureProspectDate}
                  required={isFutureProspect}
                  type={DATE}
                  view={businessPartner?.futureProspectDate}
                />
              </Grid>
            )}
          </Grid>
        </Section>
      )}
      {getShowCoinBool() && (
        <Section>
          <SectionTitle title={t("coinSection.title")} />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <CustomControl
                control={control}
                disabled={true}
                label={t("coinSection.coinSectionFields.coinDate")}
                mode={mode}
                name={BusinePartnerEntity.coinCheckDate}
                type={DATE}
                view={businessPartner?.coinCheckDate}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomControl
                control={control}
                disabled={true}
                label={t("coinSection.coinSectionFields.coinResult")}
                mode={mode}
                name={BusinePartnerEntity.coinCheckResult}
                type={TEXT}
                view={
                  coinResultStatuses[businessPartner?.coinCheckResult?.name] ||
                  businessPartner?.coinCheckResult?.name
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomControl
                control={control}
                disabled={true}
                label={t("coinSection.coinSectionFields.riskLevel")}
                mode={mode}
                name={BusinePartnerEntity.riskLevel}
                type={TEXT}
                view={businessPartner?.coinCheckRiskLevel?.name}
              />
            </Grid>
          </Grid>
        </Section>
      )}
      {getShowGreySectionBool() && (
        <Section>
          <SectionTitle title={t("greyMarketSection.title")} />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <CustomControl
                control={control}
                label={t("greyMarketSection.greyMarketSectionFields.checkPerformed")}
                mode={mode}
                name={BusinePartnerEntity.greymarketcheckperformed}
                type={CHECKBOX}
                view={businessPartner?.greyMarketCheckPerformed}
                disabled={!Boolean(isOwningRetailer)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomControl
                control={control}
                label={t("greyMarketSection.greyMarketSectionFields.checkDate")}
                mode={mode}
                name={BusinePartnerEntity.greymarketcheckdate}
                type={DATE}
                view={businessPartner?.greyMarketCheckDate}
                disabled={!Boolean(isOwningRetailer) || !isGreyPerformed}
                disableFuture={true}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomControl
                control={control}
                label={t("greyMarketSection.greyMarketSectionFields.checkResult")}
                mode={mode}
                name={BusinePartnerEntity.greymarketcheckresult}
                type={LOOKUP}
                options={greyResultOptions}
                view={
                  businessPartner?.greyMarketCheckResult?.name
                }
                disabled={!Boolean(isOwningRetailer) ||  !isGreyPerformed}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomControl
                control={control}
                label={t("greyMarketSection.greyMarketSectionFields.allDocumentsProvided")}
                mode={mode}
                name={BusinePartnerEntity.alldocumentsprovided}
                type={CHECKBOX}
                view={businessPartner?.allDocumentsProvided}
              />
            </Grid>
          </Grid>
        </Section>
      )}

      <Section>
        <SectionTitle
          title={t("businessPartnerDetailPage.mainDetails.kamTitle")}
        />

        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              disabled={
                (mode === EDIT && showOwingKaminEdit) || mode === CREATE
                  ? false
                  : true
              }
              label={t("businessPartnerDetailPage.mainDetails.owningKAM")}
              mode={mode}
              name={BusinePartnerEntity.owningKAM}
              options={owningKamServiceUsers}
              type={LOOKUP}
              required={true}
              view={businessPartner?.owningKAM?.name}
            />
          </Grid>

          {showOwningRetailerForBe && (
            <Grid item xs={12} sm={4}>
              <CustomControl
                control={control}
                disabled={
                  companyHierarchy === LEVEL3_VALUE ||
                  (mode === EDIT && !isEditable)
                }
                label={t(
                  "businessPartnerDetailPage.mainDetails.owningRetailer",
                )}
                mode={mode}
                name={BusinePartnerEntity.owningRetailer}
                options={owningRetailerOptions}
                type={LOOKUP}
                view={owningRetailer?.name}
                disableClearable={true}
                isNotAlloedToViewValue={isFieldNotAllowedToViewValue(restrictedFields, BusinePartnerEntity.owningRetailer)}
              />
            </Grid>
          )}

          {showOwningRetailerForBe && (
            <Grid item xs={12} sm={4}>
              <CustomControl
                control={control}
                disabled={!isEditable}
                label={t(
                  "businessPartnerDetailPage.mainDetails.owningRetailerContact",
                )}
                mode={mode}
                name={BusinePartnerEntity.owningRetailerContact}
                options={owningRetailerContactOptions}
                type={LOOKUP}
                view={businessPartner?.owningRetailerContact?.name}
                isNotAlloedToViewValue={isFieldNotAllowedToViewValue(restrictedFields, BusinePartnerEntity.owningRetailerContact)}
              />
            </Grid>
          )}
        </Grid>
      </Section>

      <Section>
        <SectionTitle title="ID's" />

        <Grid container spacing={3}>
          {mode !== CREATE && (
            <>
              <Grid item xs={12} sm={4}>
                <CustomControl
                  control={control}
                  disabled
                  label={t("businessPartnerDetailPage.mainDetails.globusId")}
                  mode={mode}
                  name={BusinePartnerEntity.globusId}
                  type={TEXT}
                  view={businessPartner?.globusId}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <CustomControl
                  control={control}
                  disabled
                  label={t("businessPartnerDetailPage.mainDetails.ucid")}
                  mode={mode}
                  name={BusinePartnerEntity.ucid}
                  type={TEXT}
                  view={businessPartner?.ucid}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <CustomControl
                  control={control}
                  disabled
                  label={t("businessPartnerDetailPage.mainDetails.gcid")}
                  mode={mode}
                  name={BusinePartnerEntity.gcdmId}
                  type={TEXT}
                  view={businessPartner?.gcdmId}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              label={nationalId1Label}
              mode={mode}
              name={BusinePartnerEntity.nationalId1}
              disabled={
                cloneVatConfig && bPVatNotApplicable
                  ? false
                  : disableNationalID1
              }
              type={TEXT}
              view={businessPartner?.nationalId1}
              required={
                bPVatNotApplicable && businessPartner?.vatCloned == true
                  ? true
                  : businessPartner?.vatCloned == true
                    ? false
                    : isRequiredField(BusinePartnerEntity.nationalId1)
              }
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              label={nationalId2Label}
              mode={mode}
              name={BusinePartnerEntity.nationalId2}
              type={TEXT}
              view={businessPartner?.nationalId2}
              required={isRequiredField(BusinePartnerEntity.nationalId2)}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              label={nationalId3Label}
              mode={mode}
              name={BusinePartnerEntity.nationalId3}
              type={TEXT}
              view={businessPartner?.nationalId3}
              required={isRequiredField(BusinePartnerEntity.nationalId3)}
            />
          </Grid>
          {mode !== CREATE && (
            <>
              <Grid item xs={12} sm={4}>
                <CustomControl
                  control={control}
                  label={t(
                    "businessPartnerDetailPage.mainDetails.registrationOffice",
                  )}
                  mode={mode}
                  name={BusinePartnerEntity.registrationOffice}
                  type={TEXT}
                  view={businessPartner?.registrationOffice}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <CustomControl
                  control={control}
                  disabled
                  label={t("businessPartnerDetailPage.mainDetails.dunsNumber")}
                  mode={mode}
                  name={BusinePartnerEntity.dunsNumber}
                  type={TEXT}
                  view={businessPartner?.dunsNumber}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Section>

      <Section>
        <SectionTitle title="More details" />
        <Grid container spacing={3}>
          {mode !== CREATE && (
            <>
              <Grid item xs={12} sm={4}>
                <CustomControl
                  control={control}
                  disabled
                  label={t(
                    "businessPartnerDetailPage.mainDetails.totalEmployees",
                  )}
                  mode={mode}
                  name={BusinePartnerEntity.totalNumberOfEmployees}
                  type={NUMBER}
                  view={businessPartner?.totalNumberOfEmployees}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <CustomControl
                  control={control}
                  disabled
                  label={t(
                    "businessPartnerDetailPage.mainDetails.totalActiveRetailer",
                  )}
                  mode={mode}
                  name={BusinePartnerEntity.totalNumberOfActiveRetailerRoles}
                  type={NUMBER}
                  view={businessPartner?.totalNumberOfActiveRetailerRoles}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              label={t("businessPartnerDetailPage.mainDetails.fleetSize")}
              mode={mode}
              name={BusinePartnerEntity.fleetSize}
              type={NUMBER}
              inputProps={{
                min: 0,
                ...register(BusinePartnerEntity.fleetSize, {
                  validate: {
                    fleetsize: (value) =>
                      value ? parseFloat(value) <= 1000000000 : true,
                  },
                }),
              }}
              view={businessPartner?.fleetSize}
              title={t("businessPartnerDetailPage.mainDetails.fleetSizeTitle")}
              register={register}
              required={
                companyHierarchy === LEVEL3_VALUE
                  ? false
                  : isRequiredField(BusinePartnerEntity.fleetSize)
              }
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <CustomControl
              control={control}
              label={t("businessPartnerDetailPage.mainDetails.expectedVolume")}
              mode={mode}
              name={BusinePartnerEntity.expectedVolume}
              type={NUMBER}
              inputProps={{
                min: 0,
                ...register(BusinePartnerEntity.expectedVolume, {
                  validate: {
                    expectedVolume: (value) =>
                      value ? parseFloat(value) <= 2147483647 : true,
                  },
                }),
              }}
              view={businessPartner?.expectedVolume}
              title={t(
                "businessPartnerDetailPage.mainDetails.expectedVolumeTitle",
              )}
              disabled={companyHierarchy === LEVEL3_VALUE}
              register={register}
              required={
                companyHierarchy === LEVEL3_VALUE
                  ? false
                  : isRequiredField(BusinePartnerEntity.expectedVolume)
              }
            />
          </Grid>

          {mode !== CREATE && (
            <>
              <Grid item xs={12} sm={4}>
                <CustomControl
                  control={control}
                  disabled
                  label={t(
                    "businessPartnerDetailPage.mainDetails.confirmCorporateCustomer",
                  )}
                  mode={mode}
                  name={BusinePartnerEntity.confirmedCorporateCustomer}
                  type={CHECKBOX}
                  view={businessPartner?.confirmedCorporateCustomer}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <CustomControl
                  control={control}
                  disabled
                  label={t(
                    "businessPartnerDetailPage.mainDetails.employeeConfirmation",
                  )}
                  mode={mode}
                  name={BusinePartnerEntity.employeeConfirmation}
                  type={CHECKBOX}
                  view={businessPartner?.employeeConfirmation}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <CustomControl
                  control={control}
                  disabled
                  label={t(
                    "businessPartnerDetailPage.mainDetails.receivedMotorradTerms",
                  )}
                  mode={mode}
                  name={BusinePartnerEntity.receivesMotorradTerms}
                  type={CHECKBOX}
                  view={businessPartner?.receivesMotorradTerms}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Section>

      {apiError?.isError && (
        <AppError apiError={apiError} setApiError={setApiError} />
      )}

      {showVatNotApplicableDialog && (
        <VatNotApplicableDialog
          open={showVatNotApplicableDialog}
          message={
            bPVatNotApplicable
              ? vatNotApplicableModalString
              : vatNotApplicableModalMemo
          }
          handleOk={vatNotApplicablehandleOk}
          handleClose={vatNotApplicablehandleClose}
        />
      )}
    </>
  );
};

export default BPMainDetails;
